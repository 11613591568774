export const Contents = {
  team: {
    0: [
      'Jorge Barquin is a Marine Corps veteran and graduate from the University of Florida (UF) College of Pharmacy. His family immigrated from Cuba to Miami, FL, where he was born. Jorge grew up in Palm Harbor, FL, where he and Jagger first met as opponents in rival football teams.',
      'At 19 years of age, Jorge decided to put college on hold and enlist into the United States Marine Corps. He served for 5 years, spending most of the time in Camp Pendleton, CA, along with a deployment to Afghanistan in 2010. Three days after his honorable discharge, he returned to school at the University of South Florida (USF), where he discovered a passion for organic and medicinal chemistry, which led him to pursue a career in pharmacy.',
      'It was at UF where Jorge learned clinical pharmacy through a highly touted curriculum built on a foundation of evidence-based care. During his rotations, he noticed the excessive number of patients each clinician was responsible for treating, the impact it had on their ability to apply evidence to their patients, and the frustration it caused to those who took pride in providing high-quality care to their patients. As a result, Jorge graduated and committed to finding a solution. In the Fall of that same year, Jorge founded PharmCorps with his brother, Jose, and his close friend, Jagger.',
      'During his free time, Jorge enjoys golfing, running, playing video games, hanging out with his dog, Gunner, spending time with his nephews, and reading books on quantum mechanics, particle physics, psychology, philosophy, and any other topic that sparks his curiosity.',
    ],
    1: [
      'Jagger Esch is a young entrepreneur and seasoned insurance expert with a passion for helping others that was evident at a very young age. He is originally from Dunedin, Florida, the town next to Palm Harbor, where Jorge and Jagger first met in high school.',
      "Jagger’s avid interest in the insurance industry began at the young age of 19 when he was mentored by his elder brother, who had a successful career with AmeriLife. Jagger became highly cultured in the insurance industry, becoming one of AmeriLife's leading marketers, and working with some of the top Medicare agents and agencies. By the time he was 24, he started his first insurance agency, and he is now the accomplished President and CEO of Elite Insurance Partners, LLC (EIP). At the moment, EIP is nationally ranked in the top five Medicare Supplement brokerage firms.",
      'Jagger has developed a vast knowledge of business, technology, insurance, and customer satisfaction, which is why Jorge approached him for advice shortly after graduating pharmacy school. A few months later, when Jorge was ready to start a company, he asked if Jagger would help mentor him. Jagger responded by becoming his mentor, co-founder, investor, and business partner.',
      'During his free time, Jagger enjoys boating, fishing, golf, and spending quality time with his family. As a family man, he is equally eager to help employees provide for their loved ones.',
    ],
    2: [
      'Jose Barquin is a Board Certified, Emergency Medicine Physician who has been practicing since 2014. He is the eldest of 4 boys, was born in Miami, and grew up in Palm Harbor, Florida. After graduating high school, Jose moved to Tallahassee, Florida to attend Florida State University (FSU).',
      'At FSU, Jose quickly discovered that he wanted to be a doctor, and received his Medical Degree from Florida State University’s College of Medicine. He completed Emergency Medicine residency at State University of New York, located in Buffalo, New York. Afterwards, he returned to Florida, where he currently resides.',
      'Today, Jose is Chief of Staff at his hospital (2022-2023) and Associate Medical Director of the Emergency Department. In these positions, he has gained knowledge and experience in both hospital and patient quality metrics, which includes electronic medical record improvement. In addition, he also sits on the Pinellas County EMS Medical Control Board and National Committees on Palliative Care Interventions in the Emergency Department.',
      'During his free time, Jose enjoys spending quality time with his wife and two young boys. He also enjoys soaking in the Florida life with frequent visits to the beaches and breweries. His additional interests include personal/professional growth and development, investment and real-estate ventures, intramural team sports, science fiction books, and playing video games.',
    ],
    3: [
      'Edward is an MD, healthcare executive, United States Air Force veteran, and entrepreneur who shifted focus from clinical to startups, where he felt a bigger impact can be made by improving healthcare inefficiencies and patient outcomes with companies in medtech and medical devices.',
      'Edward grew up in Leavenworth, WA, a small town nestled between orchards and mountains. Since middle school, he dreamed of becoming a physician; a journey that took him through many diverse experiences, including owning and operating multiple companies and serving in the United States Air Force. He earned his undergraduate degrees and his Doctor in Medicine at the University of Washington before starting an interventional radiology residency at his top choice: The University of Utah.',
      'During residency, however, he quickly grew frustrated with hospital workflow and system inefficiencies, and out of fear of becoming a simple cog in the wheel of medicine, started looking for ways to change the system. This began Edward’s work in venture capital, specifically with clinical and medical device startups, before eventually starting his own firm.',
      'Outside of work, Edward enjoys the outdoors, including landscape photography, fishing, and hiking with his three dogs: Bo, Bailey, & Ruger.',
    ],
  },
  solution: {
    0: {
      0: [
        'SmartADR is an adverse drug reaction surveillance app that helps to improve medication safety and optimize provider efficiency. Its unique, patent-protected, color-coded system of data retrieval and display provides clinicians with a streamlined, safer solution to ensuring that adverse drug reactions are accounted for in each medication a patient is taking. Built by a pharmacist, SmartADR was designed to protect provider autonomy by delivering real-time, around-the-clock, ADR surveillance in a manner that prevents unnecessary workflow interruptions.',
      ],
      1: [
        'Coming soon! If you would like more information on future products, please contact us at <a href="mailto:info@pharmcorps.com" class="font-semibold hover:text-black">info@pharmcorps.com</a>',
      ],
      2: [
        'Coming soon! If you would like more information on future products, please contact us at <a href="mailto:info@pharmcorps.com" class="font-semibold hover:text-black">info@pharmcorps.com</a>',
      ],
    },
    1: {
      0: [
        'Coming soon! If you would like more information on future products, please contact us at <a href="mailto:info@pharmcorps.com" class="font-semibold hover:text-black">info@pharmcorps.com</a>',
      ],
      1: [
        'Coming soon! If you would like more information on future products, please contact us at <a href="mailto:info@pharmcorps.com" class="font-semibold hover:text-black">info@pharmcorps.com</a>',
      ],
      2: [
        'Coming soon! If you would like more information on future products, please contact us at <a href="mailto:info@pharmcorps.com" class="font-semibold hover:text-black">info@pharmcorps.com</a>',
      ],
    },
  },
};
